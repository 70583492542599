import {put,takeEvery,all,call} from 'redux-saga/effects'
import {CHECK_USER_STATUS} from '../../../contants/actionTypes'
import request from '../../../service/request'

function * checkStatus(params){
    // console.log(params)
    const resData = yield call(()=>{
         return request('/spreadtool/check_user_register',{},'POST')
    })
    // alert(window.location.href)
   yield put({type:CHECK_USER_STATUS,data:resData.errcode})
    const invitecode = getQueryString()
    //判断是否通过邀请码链接进入
    // alert(invitecode)
    //用window.location.href跳转的原因是使用的hash路由，微信返回的code拼接会出现问题，（此处留下状态管理，后续等待使用BrowserRouter）
    if(invitecode){
        if(resData.errcode=='0'){
            // params.data.history.push('/mytask')
            window.location.href=window.location.origin+'/#/mytask'
        }else{
            //如果未开通权限 邀请码无效进欢迎页，有效进入申请开通页
            // alert(window.location.origin)
                const isValid =  yield call(()=>{
                    return request('/spreadtool/verify_code',{code:invitecode},'POST')
               })
               //-1为邀请码无效
               if(isValid.errcode!='-1'){
                    yield put({type:CHECK_USER_STATUS,data:0})
                    window.location.href=window.location.origin+'#/welcome?whatpage=0'
               }else if(resData.errcode=='-1001'){
                    window.location.href=window.location.origin+'#/welcome?whatpage=1'
                }else{
                    window.location.href=window.location.origin+'#/welcome?whatpage=2'
                }
        }
  
    }else{
        // alert(window.location.href)
        if(resData.errcode=='0'){
            // params.data.history.push('/mytask')
            window.location.href=window.location.origin+'/#/mytask'
       }else{
           if(resData.errcode=='-1001'){
                window.location.href=window.location.origin+'#/welcome?whatpage=1'
           }else{
                window.location.href=window.location.origin+'#/welcome?whatpage=2'
           }
            // params.data.history.push('/welcome')
           
       }
    }

   
}

function getQueryString(name){
  let value = window.location.href.split('invite=')[1]
  if(value){
    //   return value
    if(value.includes('&')){
        return value.split('&')[0]
    }else{
        return value
    }
  }else{
      return null
  }
    
 }
export default function * watchCheckStatus() {
    yield takeEvery('CHECK_USER_STATUS_SAGA', checkStatus)
}