import 'whatwg-fetch'
import qs from 'qs'
import wechatAuth from './wechat_auth'

function request(url,data={},method='GET'){
    const token = localStorage.getItem('token')
    // const token = '123'
    // url='http://t.h5wxapi.tarsocial.com/tarsocial'+url
    let baseUrl = window.location.origin.includes('ihavemoney')?"https://h5api.tarsocial.com/tarsocial":"http://t.h5wxapi.tarsocial.com/tarsocial"
    url = baseUrl+url
    let option = {
        method,
        headers:{'Content-Type':'application/x-www-form-urlencoded'}
    }
    data.token=token;
    switch(method){
        case 'GET':
        break;
        case 'POST':
        option.body=qs.stringify(data)
        break;
    }
    return new Promise((resolve,reject)=>{
        fetch(url,option)
            .then((res)=>{
                    return res.json()
                    // resolve(res.json())
                })
                .then(res=>{
                    if(res.errcode=='40001'){
                        wechatAuth.getAuth(()=>{window.location.reload(true)})
                    }else if(res.errcode == '-1001' || res.errcode == '-1002'){
                        if(url.includes('check_user_register')){
                            resolve(res)
                        }else if(res.errcode == '-1001' ){
                            window.location.href = window.location.origin+'/#/welcome?whatpage=1'
                            // window.location.href = 'http://dev.spread-assisant.tarsocial.com/#/welcome'
                        }else{
                            window.location.href = window.location.origin+'/#/welcome?whatpage=2'
                        }
                        
                    }
                    else{
                        resolve(res)
                    }
                })
                .catch((err)=>{
                    reject(err.json())
                })
    })
    
}

export default request;
