import React, { Component } from 'react';
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {TEST_STORE} from './contants/actionTypes'
import './App.css';

class App extends Component {
  constructor(props){
    super()
  }
  render() {
    const { test_data } = this.props
    return (
      <div className="App">
        12123,
        {this.props.test_info.data.id}
        <button onClick={this.props.test_data.bind(this, 'aaaaa')}>点击一下</button>
      </div>
    );
  }
}
const mapStateToProps = (state)=>{
  return{
    test_info:state.test
  }
}
const mapDispatchToProps = dispatch => {
  return {
    test_data(argus){
      console.log(argus)
      dispatch({
        type:'TEST_STORE1',
        data:{id:1}
      })
    }
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
