import {put,takeEvery,all,call} from 'redux-saga/effects'
import {GET_BALANCE} from '../../../contants/actionTypes'
import request from '../../../service/request'

function * getBalance(params){
    
    const resData = yield call(()=>{
         return request('/spreadtool/get_user_wallet_info',{},'POST')
    })
   yield put({type:GET_BALANCE,data:resData.data})
}
export default function * watchBalance() {
    yield takeEvery('GET_BALANCE_SAGA', getBalance)
}