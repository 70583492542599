import {put,takeEvery,all,call} from 'redux-saga/effects'
import {GET_TASK_LIST,GET_FUND_LIST} from '../../../contants/actionTypes'
import request from '../../../service/request'

function * getTaskList(params){
    // await console.log(21312312)

    // const data = params
    // console.log(params)
    const resData = yield call(()=>{
         return request('/spreadtool/get_article_list',params.data,'POST')
    })
    resData.data.page=params.data.page;
   yield put({type:GET_TASK_LIST,data:resData.data})
}
function * getFund(params){
    const resData = yield call(()=>{
        return request('/spreadtool/get_wallet_log',params.data,'POST')
   })
   resData.data.page=params.data.page;
   yield put({type:GET_FUND_LIST,data:resData.data})
}
export default function * watchTaskList() {
    yield takeEvery('GET_LIST_SAGA', getTaskList)
    yield takeEvery('GET_FUND_SAGA',getFund)
}