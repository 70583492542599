import 'whatwg-fetch'
import qs from 'qs'

class WechatAuth{
    constructor(){

    }
    getAuth(callback){
        const code=this.getQueryString('code')
        // console.log(window.location.hash)
        if(code){
            // console.log(code)
            // this.wxShareFuc()
            let data={code,appid:'wx95cbb2d8f1fa100c'}
            fetch('https://api.tarsocial.com/v1/wxopen/mp_auth_fansinfo',{
                method:"POST",
                body:qs.stringify(data),
                headers:{'Content-Type':'application/x-www-form-urlencoded'}
            }).then(res=>{
                return res.json()
            })
            .then(res=>{
                // console.log(res)
                if(res.errcode==0){
                    localStorage.setItem('USERINFO',JSON.stringify(res.data))
                    localStorage.setItem('token',res.token)
                    if (callback) callback()
                    // this.isRegister()
                }
             
            })
        }else{
            // console.log(window.location.href)  http://dev.spread-assisant.tarsocial.com
            const redirect_url = window.location.href
            // alert(redirect_url)
            window.location.href='https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx95cbb2d8f1fa100c&redirect_uri='+encodeURIComponent(redirect_url)+'&response_type=code&scope=snsapi_userinfo&state==STATE#wechat_redirect'
        }
    }
    wxShareFuc(){
        let data = {version:'plantearth',shareurl:encodeURIComponent(window.location.origin)}
        fetch('http://api.h5mo.com/v1/wxopen/wxshare',{
            method:"POST",
            body:qs.stringify(data),
            headers:{'Content-Type':'application/x-www-form-urlencoded'}
        }).then(res=>{
            return res.json()
        }).then(res=>{
            // console.log(res)
            window.wx.config({
                debug:true,
                appi:res.appid,
                timestamp:res.timestamp,
                nonceStr:res.noncestr,
                signature:res.signature,
                jsApiList:['onMenuShareTimeline','onMenuShareAppMessage']
            })
            window.wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
                window.wx.updateAppMessageShareData({ 
                    title: '传播总动员', // 分享标题
                    desc: '后营销时代的品牌传播终极解决方案', // 分享描述
                    link: window.location.origin + window.location.hash, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: '', // 分享图标
                    success: function () {
                      // 设置成功
                    }
                })
            })
            window.wx.updateTimelineShareData({ 
                title: '传播总动员 - 后营销时代的品牌传播终极解决方案', // 分享标题
                link: window.location.origin + window.location.hash, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致window.location.origin + window.location.hash
                imgUrl: '', // 分享图标
                success: function () {
                  // 设置成功
                }
            })
        })
    }
    getQueryString(name){
        let reg = new RegExp("(^|&)"+ name +"=([^&]*)(&|$)");
        if(window.location.href.split('?')[1]){
            let r = window.location.href.split('?')[1].match(reg);
        if(r!=null)
            return  (r[2]);
        return null;
        }else{
            return null
        }
        
     }
}

export default new WechatAuth()