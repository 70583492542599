import {
    CHECK_USER_STATUS
} from '../contants/actionTypes'

const user = {
    status:'-1001'
}
export default function checkStatus(state = user, action) {
    // console.log(action)
    switch (action.type) {
        case CHECK_USER_STATUS:
            return {
                ...state,
                status:action.data
            }

        default:
            return state
    }
}