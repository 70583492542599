import {combineReducers} from 'redux'
import {routerReducer} from 'react-router-redux';
import test from './test'
import getTaskList from './getTaskList'
import getBalance from './getBalance'
import checkUserStatus from './checkUserStatus'
import getRankList from './getRankList'
import getFundList from './getFundList'

const rootReducer = combineReducers({
    test,
    getTaskList,
    getBalance,
    checkUserStatus,
    routing:routerReducer,
    getRankList,
    getFundList
})
export default rootReducer