import {put,takeEvery,all,call} from 'redux-saga/effects'
import {GET_RANK_LIST} from '../../../contants/actionTypes'
import request from '../../../service/request'

function * getRankList(params){
    // console.log(params)
    const resData = yield call(()=>{
         return request('/spreadtool/get_spread_ranking',params.data,'POST')
    })
    // console.log(resData)
    // console.log(resData)
    resData.data.page=params.data.page;
   yield put({type:GET_RANK_LIST,data:resData.data})
   
}
export default function * watchRankList() {
    yield takeEvery('GET_RANK_LIST_SAGA', getRankList)
}