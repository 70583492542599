import React from 'react';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import {render} from 'react-dom';
import {BrowserRouter,Switch,Route,Redirect,HashRouter} from 'react-router-dom'
import {createStore, applyMiddleware} from 'redux'
import {Provider} from 'react-redux'
import createSagaMiddleware from 'redux-saga'
import Loadable from 'react-loadable'
import reducer from './reducers'
import Loading from './components/comComponents/loading'
import rootSaga from './sagas/index'
// import InitScope from './pages/initPage/initScope'
// import MyTaskList from './pages/taskList/my_task_list'
// import AddMonitor from './pages/addMonitor/add_monitor'
// import WelcomePage from './pages/welcome/welcome_use'
// import BalancePage from './pages/my_balance/balance_page'
// import SpreadAnalyze from './pages/spread_analyze/spreadAnalyze'
import './css/base.css'

import 'whatwg-fetch'
import qs from 'qs'

const sagaMiddleware = createSagaMiddleware()

export const store = createStore(
    reducer,
    applyMiddleware(sagaMiddleware)
)
sagaMiddleware.run(rootSaga)

//根据路由进行代码拆分
const InitScope = Loadable({
    loader:()=>import('./pages/initPage/initScope'),
    loading:Loading
})
const MyTaskList = Loadable({
    loader:()=>import('./pages/taskList/my_task_list'),
    loading:Loading
})
const AddMonitor = Loadable({
    loader:()=>import('./pages/addMonitor/add_monitor'),
    loading:Loading
})
const WelcomePage = Loadable({
    loader:()=>import('./pages/welcome/welcome_use'),
    loading:Loading
})
const BalancePage = Loadable({
    loader:()=>import('./pages/my_balance/balance_page'),
    loading:Loading
})
const SpreadAnalyze = Loadable({
    loader:()=>import('./pages/spread_analyze/spreadAnalyze'),
    loading:Loading
})
wxShareFuc()

//cross-env PUBLIC_URL=http://h5cdn.tarsocial.com/spread-assisant 

function wxShareFuc(){
    let data = {shareurl:window.location.href,host:window.location.host}
    console.log(data,'data')
    fetch('http://api.h5mo.com/v1/wxopen/wxshare',{
        method:"POST",
        body:qs.stringify(data),
        headers:{'Content-Type':'application/x-www-form-urlencoded'}
    }).then(res=>{
        return res.json()
    }).then(res=>{
        // console.log(res)
        window.wx.config({
            debug:false,
            appId:res.appid,
            timestamp:res.timestamp,
            nonceStr:res.noncestr,
            signature:res.signature,
            jsApiList:['onMenuShareAppMessage','onMenuShareTimeline']
        })
        window.wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
            window.wx.onMenuShareAppMessage({ 
                title: '传播总动员', // 分享标题
                desc: '后营销时代的品牌传播终极解决方案', // 分享描述
                link: window.location.origin + window.location.hash, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: 'http://h5cdn.tarsocial.com/spread-assisant/images/logo_icon.png', // 分享图标
                success: function () {
                  // 设置成功
                }
            })
            window.wx.onMenuShareTimeline({ 
                title: '传播总动员 - 后营销时代的品牌传播终极解决方案', // 分享标题
                link: window.location.origin + window.location.hash, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致window.location.origin + window.location.hash
                imgUrl: 'http://h5cdn.tarsocial.com/spread-assisant/images/logo_icon.png', // 分享图标
                success: function () {
                  // 设置成功
                }
            })
        })
       
    })
}

const MainScope = ({location})=>{
    console.log(location)
    // alert(222)
    // alert(window.location.href)
    return(
        <div style={{height:'100%'}}>
            <Switch>
                <Route exact path="/" render={() => <Redirect to="/init" />} />
                <Route path='/init' component={InitScope}/>
                <Route path='/mytask' component={MyTaskList}/>
                <Route path='/addmonitor' component={AddMonitor}/>
                <Route path='/welcome' component={WelcomePage}/>
                <Route path='/balance' component={BalancePage}/>
                <Route path='/spreadAnalyze' component={SpreadAnalyze}/>
            </Switch>
        </div>
    )
}

render(
    <Provider store={store}>
        <HashRouter>
            <Switch>
                <Route path='/' component={MainScope}/>
                <Route path='/init' component={InitScope}/>
            </Switch>
        </HashRouter>
    </Provider>
,document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.register();
