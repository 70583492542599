import {
    GET_RANK_LIST
} from '../contants/actionTypes'

const ranklist = {
    list:[],
    have_next:1,
}
export default function getRankList(state = ranklist, action) {
    // console.log(action)
    switch (action.type) {
        case GET_RANK_LIST:
            return {
                ...state,
                list:action.data.page==1?action.data.list:state.list.concat(action.data.list),
                have_next:action.data.have_next
            }

        default:
            return state
    }
}