import {put,takeEvery,all,call} from 'redux-saga/effects'
import watchTaskList from '../pages/taskList/saga/taskList'
import watchBalance from '../pages/my_balance/saga/balance'
import watchCheckStatus from '../pages/initPage/saga/checkStatus'
import watchRankList from '../pages/spread_analyze/saga/rankList'
import {TEST_STORE} from '../contants/actionTypes'

function * test(data){
    yield console.log(data)
    yield put({ type: TEST_STORE, data: {id:'21',name:'jackson'} })
}

function* watchIncrementAsync() {
    yield takeEvery('TEST_STORE1', test)
}
export default function *rootSaga(){
    yield all([
        watchIncrementAsync(),
        watchTaskList(),
        watchBalance(),
        watchCheckStatus(),
        watchRankList()
    ])
}