import {
    TEST_STORE
} from '../contants/actionTypes'

const test_data = {
    data: {id:1}
}
export default function testInfo(state = test_data, action) {

    switch (action.type) {
        case TEST_STORE:
            return {
                data: action.data
            }

        default:
            return state
    }
}