import {
    GET_TASK_LIST,GET_FUND_LIST
} from '../contants/actionTypes'

const task_list = {
    detail: {
        list:[],
        have_next:1,
    },
}
export default function getTaskList(state = task_list, action) {
    // console.log(action)
    switch (action.type) {
        case GET_TASK_LIST:
            return {
                detail: {
                    list:action.data.page==1?action.data.list:state.detail.list.concat(action.data.list),
                    have_next:action.data.have_next
                }
            }
        default:
            return state
    }
}