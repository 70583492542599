import {
    GET_BALANCE
} from '../contants/actionTypes'

const balance = {
    money:''
}
export default function getTaskList(state = balance, action) {
    // console.log(action)
    switch (action.type) {
        case GET_BALANCE:
            return {
                ...state,
                money:action.data
            }

        default:
            return state
    }
}